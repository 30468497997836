import { noop } from 'lodash';
import React, { FC } from 'react';

import { isDeviceCategory, ItemGrade, Permission, ProductCategory, WarehouseRefDto } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { nowDate, toISODate } from '@hofy/helpers';
import {
    Box,
    FormDateInput,
    FormFieldRecord,
    FormInput,
    IconButton,
    InnerCard,
    LabeledSelect,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { WarehouseBinSelector } from '../../../../../components/domain/warehouses/WarehouseBinSelector';
import { ReceiveBYODOrderItemDetailFormData } from '../../../../../store/byodOrders/types/ReceiveBYODOrderItemFormData';
import { useTrItemGrade } from '../../../../../store/items/useTrItemGrade';

interface BYODOrderItemDetailsProps {
    itemForm: FormFieldRecord<ReceiveBYODOrderItemDetailFormData>;
    index: number;
    category: ProductCategory;
    warehouse: WarehouseRefDto;
    warehouseBinRequired: boolean;
    deleteItem(index: number): void;
}

export const BYODOrderItemDetails: FC<BYODOrderItemDetailsProps> = ({
    itemForm,
    index,
    category,
    warehouse,
    warehouseBinRequired,
    deleteItem,
}) => {
    const trItemGrade = useTrItemGrade();

    const { hasPermission } = useSession();
    const canAddWarehouseBin = hasPermission(Permission.AdminWarehouseManage);

    return (
        <InnerCard padding={20} row fullWidth justify='space-between' alignItems='flex-start'>
            <Box fullHeight justify='flex-start'>
                <Paragraph3>{index + 1}.</Paragraph3>
            </Box>
            <FormInput
                label='Item code'
                api={itemForm.itemCode}
                autoFocus={index === 0}
                isRequired
                nullable
            />
            <FormInput
                label='Serial number'
                api={itemForm.serialNumber}
                isRequired={isDeviceCategory(category)}
                nullable
            />
            <WarehouseBinSelector
                api={itemForm.warehouseBinIdentifier}
                warehouse={warehouse}
                isRequired={warehouseBinRequired}
                isAddBinPromptEnabled={canAddWarehouseBin}
            />
            <FormDateInput
                label='Date received'
                api={itemForm.receivedOn}
                maxDate={toISODate(nowDate())}
                isRequired
            />
            <LabeledSelect
                label='Condition'
                value={ItemGrade.New}
                onChange={noop}
                nullable={false}
                options={[ItemGrade.New]}
                toText={trItemGrade}
                disabled
            />
            <IconButton icon={SvgIcon.Cross} onClick={() => deleteItem(index)} />
        </InnerCard>
    );
};
