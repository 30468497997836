import React, { FC } from 'react';
import styled from 'styled-components';

import { ActivityLogEventDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, FeaturedIcon, Paragraph3, Paragraph4, TestKeyAware } from '@hofy/ui';

import { useTrActivityLogEventKind } from '../store/useTrActivityLogEventKind';
import { LinkType } from '../types/LinkType';
import { ActivityEventDescription } from './ActivityEventDescription';
import { getActivityLogIcon } from './ActivityLogIcons';

interface ActivityEventProps extends TestKeyAware {
    event: ActivityLogEventDto;

    generateLink(type: LinkType, uuid: UUID): string;
}

const iconSize = 32;

export const ActivityEvent: FC<ActivityEventProps> = ({ event, generateLink, testKey }) => {
    const trActivityLogEventKind = useTrActivityLogEventKind();

    return (
        <ActivityWrapper row alignItems='stretch' gap={24} data-test-key={testKey}>
            <VerticalLineWrapper column relative>
                <FeaturedIcon icon={getActivityLogIcon(event.kind)} type='neutral' size={iconSize} />
            </VerticalLineWrapper>
            <Wrapper column flex={1}>
                <Paragraph4>{formatDate(event.occuredOn)}</Paragraph4>
                <Box column gap={8}>
                    <Paragraph3 bold color={Color.ContentPrimary}>
                        {trActivityLogEventKind(event.kind)}
                    </Paragraph3>
                    <ActivityEventDescription event={event} generateLink={generateLink} />
                </Box>
            </Wrapper>
        </ActivityWrapper>
    );
};

const ActivityWrapper = styled(Box)``;
const Wrapper = styled(Box)`
    ${ActivityWrapper}:not(:last-child) > & {
        padding-bottom: 40px;
    }
`;
const VerticalLineWrapper = styled(Box)`
    ${ActivityWrapper}:not(:last-child) > &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: calc(100% - ${iconSize}px);
        top: ${iconSize}px;
        left: 50%;
        transform: translateX(-50%);
        background-color: ${Color.NonContextualBorderDivider};
    }
`;
