import { DateString, getEnumValues, UUID } from '@hofy/global';

import { ItemStatus } from './ItemStatus';
import { Ownership } from './Ownership';
import { RepairStatus } from './RepairStatus';

export interface ActivityLogBaseEvent<Event extends ActivityLogEventKind> {
    kind: Event;
    occuredOn: DateString;
}

export enum ActivityLogEventKind {
    // assets
    AssetCreatedWithUser = 'asset.created_with_user',
    AssetCreatedAtWarehouse = 'asset.created_at_warehouse',
    AssetPendingDelivery = 'asset.pending_delivery',
    AssetPendingCollection = 'asset.pending_collection',
    AssetPendingTransfer = 'asset.pending_transfer',
    AssetTransferredBetweenUsers = 'asset.transferred_between_users',
    AssetArchived = 'asset.archived',
    AssetCollectedToWarehouse = 'asset.collected_to_warehouse',
    AssetDeliveredFromWarehouse = 'asset.delivered_from_warehouse',
    AssetPendingRepair = 'asset.pending_repair',
    AssetRepairResolved = 'asset.repair_resolved',
    AssetInspectionCompleted = 'asset.inspection_completed',
    // collections
    CollectionRequestCreated = 'collection.request_created',
    CollectionRequestEscalated = 'collection.request_escalated',
    CollectionRequestDeferred = 'collection.request_deferred',
    CollectionRequestDeEscalated = 'collection.request_de_escalated',
    CollectionRequestDeDeferred = 'collection.request_de_deferred',
    CollectionRequestCompleted = 'collection.request_completed',
    CollectionRequestCancelled = 'collection.request_cancelled',
    CollectionRequestEmailSent = 'collection.request_email_sent',
    CollectionRequestEmailReminderSent = 'collection.request_email_reminder_sent',
    CollectionRequestSmsReminderSent = 'collection.request_sms_reminder_sent',
    CollectionShipmentBooked = 'collection.shipment_booked',
    CollectionShipmentCompleted = 'collection.shipment_completed',
}

export const allActivityLogEventKinds = getEnumValues<ActivityLogEventKind>(ActivityLogEventKind);

export type ActivityLogEventDto = AssetActivityLogEventDto;

export type AssetActivityLogEventDto =
    | AssetCreatedWithUserEvent
    | AssetCreatedAtWarehouseEvent
    | AssetPendingDeliveryEvent
    | AssetPendingCollectionEvent
    | AssetPendingTransferEvent
    | AssetTransferredBetweenUsersEvent
    | AssetArchivedEvent
    | AssetCollectedToWarehouseEvent
    | AssetDeliveredFromWarehouseEvent
    | AssetPendingRepairEvent
    | AssetRepairResolvedEvent
    | CollectionRequestCreatedEvent
    | CollectionRequestEscalatedEvent
    | CollectionRequestDeferredEvent
    | CollectionRequestDeEscalatedEvent
    | CollectionRequestDeDeferredEvent
    | CollectionRequestCompletedEvent
    | CollectionRequestCancelledEvent
    | CollectionRequestEmailSentEvent
    | CollectionRequestEmailReminderSentEvent
    | CollectionRequestSmsReminderSentEvent
    | CollectionShipmentBookedEvent
    | CollectionShipmentCompletedEvent
    | AssetInspectionCompletedEvent;

export interface AssetCreatedWithUserEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetCreatedWithUser> {
    createdWithUser: {
        assetType: AssetType;
        user: AssetEventUserDto;
        relative: AssetEventRelativeDto;
    };
}

export interface AssetCreatedAtWarehouseEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetCreatedAtWarehouse> {
    createdAtWarehouse: {
        warehouse: AssetEventWarehouseDto;
    };
}

export interface AssetPendingDeliveryEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetPendingDelivery> {
    pendingDelivery: {
        to: AssetEventUserDto;
    };
}

export interface AssetPendingCollectionEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetPendingCollection> {
    pendingCollection: {
        from: AssetEventUserDto;
    };
}

export interface AssetPendingTransferEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetPendingTransfer> {
    pendingTransfer: {
        from: AssetEventUserDto;
        to: AssetEventUserDto;
    };
}

export interface AssetTransferredBetweenUsersEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetTransferredBetweenUsers> {
    transferredBetweenUsers: {
        from: AssetEventUserDto;
        to: AssetEventUserDto;
        via: EventShipmentDto | null;
    };
}

export interface AssetArchivedEvent extends ActivityLogBaseEvent<ActivityLogEventKind.AssetArchived> {
    archived: {
        status: ItemStatus;
    };
}

export interface AssetCollectedToWarehouseEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetCollectedToWarehouse> {
    collected: {
        from: AssetEventUserDto;
        to: AssetEventWarehouseDto;
        via: EventShipmentDto | null;
    };
}

export interface AssetDeliveredFromWarehouseEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetDeliveredFromWarehouse> {
    delivered: {
        to: AssetEventUserDto;
        from: AssetEventWarehouseDto;
        via: EventShipmentDto | null;
    };
}

export interface AssetPendingRepairEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetPendingRepair> {}

export interface AssetRepairResolvedEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetRepairResolved> {
    repairResolved: {
        status: RepairStatus;
    };
}

export interface AssetInspectionCompletedEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.AssetInspectionCompleted> {}

export interface CollectionRequestCreatedEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionRequestCreated> {}

export interface CollectionRequestEmailSentEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionRequestEmailSent> {}

export interface CollectionRequestEmailReminderSentEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionRequestEmailReminderSent> {}

export interface CollectionRequestSmsReminderSentEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionRequestSmsReminderSent> {}

export interface CollectionRequestEscalatedEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionRequestEscalated> {}

export interface CollectionRequestDeferredEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionRequestDeferred> {}

export interface CollectionRequestDeEscalatedEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionRequestDeEscalated> {}

export interface CollectionRequestDeDeferredEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionRequestDeDeferred> {}

export interface CollectionRequestCompletedEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionRequestCompleted> {}

export interface CollectionRequestCancelledEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionRequestCancelled> {}

export interface CollectionShipmentBookedEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionShipmentBooked> {
    shipmentBooked: {
        shipment: EventShipmentDto;
    };
}

export interface CollectionShipmentCompletedEvent
    extends ActivityLogBaseEvent<ActivityLogEventKind.CollectionShipmentCompleted> {
    shipmentCompleted: {
        shipment: EventShipmentDto;
    };
}

type AssetType = 'external' | 'standard' | 'loaner' | 'replacement';

export interface AssetEventUserDto {
    id: UUID;
    firstName: string;
    lastName: string;
}

export interface AssetEventWarehouseDto {
    id: string | null;
    name: string;
    ownership: Ownership;
}

export interface EventShipmentDto {
    publicId: string;
    id: UUID;
}

type AssetEventRelativeType = 'assignment' | 'item' | 'request';

export interface AssetEventRelativeDto {
    type: AssetEventRelativeType;
    publicId: string;
    id: UUID;
}
