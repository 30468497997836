import React, { FC } from 'react';

import { useBYODOrders } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { ExportButton, Page } from '@hofy/common';
import { UUID } from '@hofy/global';
import { ActiveFilters, ActiveFiltersLayout, Button, FilterSearchInput, PageHeader, SvgIcon } from '@hofy/ui';

import { useBYODOrdersFilters } from '../../../store/byodOrders/useBYODOrderFilters';
import { useBYODOrdersExport } from '../../../store/byodOrders/useBYODOrdersExport';
import { InventoryTab } from '../../../store/inventory/types/InventoryTab';
import { InventoryTabs } from '../InventoryTabs';
import { BYODOrderTable } from './components/BYODOrderTable';

interface BYODOrdersPageProps {
    onReceiveBYODOrder(id: UUID): void;
    onNewBYODOrder(): void;
}

export const BYODOrdersPage: FC<BYODOrdersPageProps> = ({ onReceiveBYODOrder, onNewBYODOrder }) => {
    const { filters, filterValues, filterCount, clearFilters } = useBYODOrdersFilters();
    const { byodOrders, byodOrdersIsLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useBYODOrders(filterValues);

    const { hasPermission } = useSession();
    const { download, isDownloading } = useBYODOrdersExport(filterValues);

    return (
        <Page pageId='byod-orders'>
            <PageHeader
                title='BYOD orders'
                rightSlot={
                    <>
                        <ExportButton onClick={download} disabled={isDownloading} />
                        <FilterSearchInput filter={filters.search} autoFocus />
                        <Button
                            leftIcon={SvgIcon.Add}
                            label='New BYOD order'
                            disabled={!hasPermission(Permission.AdminBYODOrdersCreate)}
                            onClick={onNewBYODOrder}
                        />
                    </>
                }
                tabsSlot={<InventoryTabs active={InventoryTab.BYODOrders} />}
            />

            <ActiveFiltersLayout show={filterCount > 0}>
                <ActiveFilters filters={filters} onClearAll={clearFilters} />
            </ActiveFiltersLayout>

            <BYODOrderTable
                byodOrders={byodOrders}
                onReceiveBYODOrder={onReceiveBYODOrder}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: byodOrdersIsLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
                filters={filters}
            />
        </Page>
    );
};
