import React, { FC, useState } from 'react';

import { AssignmentDetailsDto, ListProductsOptions, useAssignmentQuery } from '@hofy/api-admin';
import { Slideout, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import {
    AsyncButton,
    Box,
    Button,
    ErrorStatePlaceholder,
    isRequired,
    Paragraph3,
    RequiredKeys,
    SearchInput,
    SectionTitle3,
    Skeleton,
    SkeletonList,
    SvgIcon,
    useForm,
    validator,
} from '@hofy/ui';

import { ProductOverview } from '../../../components/domain/products/ProductOverview';
import {
    ProductAndVariant,
    VariantPicker,
} from '../../../components/domain/products/productPicker/VariantPicker';
import { usePickCustomProduct } from '../../../store/assignments/usePickCustomProduct';
import { useSubstituteProduct } from '../../../store/assignments/useSubstituteProduct';
import { AssignmentLink } from '../../assignmentsPage/AssignmentLink';

interface VariantPickerFormData {
    productAndVariant: ProductAndVariant | null;
}

enum VariantPickerSlideoutTypes {
    PickCustomProduct = 'pick-custom-product',
    SubstituteProduct = 'substitute-product',
}

interface VariantPickerSlideoutTitles {
    slideoutTitle: string;
    assignmentSectionTitle: string;
    variantSectionTitle: string;
    submitButtonLabel: string;
}

const variantPickerSlideoutTitles: Record<VariantPickerSlideoutTypes, VariantPickerSlideoutTitles> = {
    [VariantPickerSlideoutTypes.PickCustomProduct]: {
        slideoutTitle: 'Pick custom product',
        assignmentSectionTitle: 'Requested product',
        variantSectionTitle: 'Possible products',
        submitButtonLabel: 'Pick product',
    },
    [VariantPickerSlideoutTypes.SubstituteProduct]: {
        slideoutTitle: 'Substitute product',
        assignmentSectionTitle: 'Requested product',
        variantSectionTitle: 'Possible substitutes',
        submitButtonLabel: 'Substitute product',
    },
};

interface VariantPickerSlideoutProps {
    type: VariantPickerSlideoutTypes;

    slideoutId: string;
    assignmentId: UUID;
    isSubmitLoading: boolean;
    isSubmitError: boolean;

    onSubmit(variant: ProductAndVariant): void;
    onClose(): void;
}

const VariantPickerSlideout: FC<VariantPickerSlideoutProps> = ({
    type,
    slideoutId,
    assignmentId,
    isSubmitLoading,
    isSubmitError,
    onSubmit,
    onClose,
}) => {
    const [search, setSearch] = useState<string>('');

    const form = useForm<VariantPickerFormData, RequiredKeys<VariantPickerFormData>>({
        initial: {
            productAndVariant: null,
        },
        validate: validator<VariantPickerFormData>({
            productAndVariant: isRequired('Product and variant must be selected'),
        }),
        onSubmit: values => {
            onSubmit(values.productAndVariant);
        },
    });

    const { slideoutTitle, assignmentSectionTitle, variantSectionTitle, submitButtonLabel } =
        variantPickerSlideoutTitles[type];

    return (
        <Slideout width={600} onClose={onClose} slideoutId={slideoutId}>
            <SlideoutHeader title={slideoutTitle} justify='space-between' paddingRight={20}>
                <SearchInput value={search} onChange={setSearch} placeholder='Search product, SKU, …' />
            </SlideoutHeader>
            <VariantPickerSlideoutBody
                assignmentId={assignmentId}
                assignmentSectionTitle={assignmentSectionTitle}
                variantSectionTitle={variantSectionTitle}
                onPick={productAndVariant => {
                    form.setValues({ productAndVariant });
                }}
                selected={form.values.productAndVariant}
                productOptionsFromAssignment={assignment => ({
                    organizationIds: [assignment.organization.id],
                    categories: [assignment.product.category],
                    search,
                })}
            />
            <SlideoutFooter>
                <Button
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                    label='Cancel'
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton
                    label={submitButtonLabel}
                    isError={isSubmitError}
                    isLoading={isSubmitLoading}
                    onClick={form.submit}
                    disabled={!form.values.productAndVariant}
                />
            </SlideoutFooter>
        </Slideout>
    );
};

interface VariantPickerSlideoutBodyProps {
    assignmentId: UUID;
    assignmentSectionTitle: string;
    onPick(pv: ProductAndVariant): void;
    selected: ProductAndVariant | null;
    variantSectionTitle: string;
    productOptionsFromAssignment(assignment: AssignmentDetailsDto): Partial<ListProductsOptions>;
}

export const VariantPickerSlideoutBody: FC<VariantPickerSlideoutBodyProps> = ({
    assignmentId,
    assignmentSectionTitle,
    onPick,
    selected,
    variantSectionTitle,
    productOptionsFromAssignment,
}) => {
    const {
        data: assignment,
        isLoading: isLoadingAssignment,
        isError: isAssignmentError,
    } = useAssignmentQuery(assignmentId);

    if (isAssignmentError) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <>
            <Box borderBottom column paddingHorizontal='mainMarginHorizontal' paddingVertical={20} gap={20}>
                <SectionTitle3>{assignmentSectionTitle}</SectionTitle3>
                <Box row gap={20}>
                    {isLoadingAssignment || !assignment ? (
                        <Skeleton fullWidth height={60} />
                    ) : (
                        <>
                            <Paragraph3>
                                <AssignmentLink id={assignmentId}>#{assignmentId}</AssignmentLink>
                            </Paragraph3>
                            <ProductOverview
                                imageSize={60}
                                product={assignment.product}
                                images={assignment.product.image}
                            />
                        </>
                    )}
                </Box>
            </Box>
            <Box borderBottom column paddingVertical={20} gap={20} flex='auto'>
                <SectionTitle3 paddingHorizontal='mainMarginHorizontal'>{variantSectionTitle}</SectionTitle3>
                {isLoadingAssignment || !assignment ? (
                    <SkeletonList items={5} height={60} gap={16} marginHorizontal={40} />
                ) : (
                    <VariantPicker
                        onPick={onPick}
                        selected={selected}
                        listProductsOptions={productOptionsFromAssignment(assignment)}
                    />
                )}
            </Box>
        </>
    );
};

interface BaseVariantPickerSlideoutProps {
    assignmentId: UUID;
    onClose(): void;
}

export const PickCustomProductSlideout: FC<BaseVariantPickerSlideoutProps> = ({ assignmentId, onClose }) => {
    const { updateCustomVariant, isPending, isError } = usePickCustomProduct(assignmentId, onClose);
    return (
        <VariantPickerSlideout
            type={VariantPickerSlideoutTypes.PickCustomProduct}
            slideoutId='pick-custom-product'
            assignmentId={assignmentId}
            isSubmitLoading={isPending}
            isSubmitError={isError}
            onSubmit={selected => updateCustomVariant({ customVariantId: selected.variant.id })}
            onClose={onClose}
        />
    );
};

export const SubstituteProductSlideout: FC<BaseVariantPickerSlideoutProps> = ({ assignmentId, onClose }) => {
    const { substitute, isPending, isError } = useSubstituteProduct(assignmentId, onClose);
    return (
        <VariantPickerSlideout
            type={VariantPickerSlideoutTypes.SubstituteProduct}
            slideoutId='substitute-product'
            assignmentId={assignmentId}
            isSubmitLoading={isPending}
            isSubmitError={isError}
            onSubmit={selected => substitute({ variantId: selected.variant.id })}
            onClose={onClose}
        />
    );
};
